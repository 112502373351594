import React, { Component } from 'react'
import { connect } from 'react-redux'
import Quotes from '../components/common/Quotes';
import { Typography } from '@material-ui/core';
import DetailDialog from "./layout/purchase/DetailDialogV2";


class TotalApagar extends Component {
    /*  constructor(props) {
         super(props);
     } */
    render() {
        //console.log("TotalApagar",this.props)

        const purchaseDetail = (this.props.stepReducerV2.purchase !== null && Object.keys(this.props.stepReducerV2.purchase).length > 0) ? this.props.stepReducerV2.purchase : this.props.purchaseDetail;

        let newAddToCart = {}
        newAddToCart["priceDetailProduct"] = this.props.purchaseDetail.priceDetail.filter(
            (element) => element.addToCart === true
        );
        newAddToCart["priceDetailCards"] = []

        const addToCart = (this.props.stepReducerV2.addToCart !== null) ? this.props.stepReducerV2.addToCart : newAddToCart;
        const products = this.props.products;
        const selectedData = this.props.selectedData;
        const installment = this.props.installment;
        const badge = purchaseDetail.priceDetail.filter(
            (element) => element.addToCart === true
        );
        let priceDetailProduct = null;
        let priceDetailCards = null;
        if (addToCart.priceDetailProduct !== undefined && addToCart.priceDetailCards !== undefined) {
            // console.log("addToCart en	 purchaseDetailV2", addToCart)
            priceDetailProduct = addToCart.priceDetailProduct;
            priceDetailCards = addToCart.priceDetailCards;
        } else { // setea valor por defecto para que no de error.
            priceDetailProduct = [];
            priceDetailCards = [];
        }
        let purchaseReducer = (this.props.stepReducerV2.purchase !== null) ? this.props.stepReducerV2.purchase : purchaseDetail;
        // console.log('purchaseDetail TotalApagar', purchaseReducer)
        return (

            <div className='total-a-pagar-steps-button'>
                <div className='price-detail-total'>
                    <span className="text">Total a pagar</span>
                    <span className="price">{purchaseReducer.currency}<span className="amount">{purchaseReducer.totalAmount}</span></span>
                    <DetailDialog
                        installment={installment}
                        selectedData={selectedData}
                        icon='info_outlined'
                        purchaseDetail={purchaseDetail}
                        products={products}
                        badgeContent={badge.length}
                        priceDetailProduct={priceDetailProduct}
                        priceDetailCards={priceDetailCards}
                    />
                </div>

                {priceDetailCards.length > 0 &&
                    <div className='price-detail-quotes'>
                        {priceDetailCards.map((item, i) => {
                            return (
                                <Typography key={i} variant='body2' style={{ fontSize: 12 }}>
                                    <Quotes length={priceDetailCards.length} item={item} currency={purchaseReducer.currency} />
                                </Typography>

                            )
                        })}
                    </div>
                }


            </div>
        )
    }
}

const mapStateToProps = reducers => {
    return reducers;
};
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TotalApagar)