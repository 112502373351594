import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Popover, Typography, IconButton, Icon} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    width: '100%',
    maxWidth: '270px',
    padding: '12px',
    paddingBottom: '6px'
  },
}));

export default function InfoPopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Fragment>
        <IconButton 
            className="info_charge_button" 
            aria-label={props.title} 
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
          <Icon>{props.icon}</Icon>
        </IconButton>
        <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
            paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Fragment>
              {/* <Typography>{props.title}</Typography> */}

              {props.charge.map((item, i) => {
                  // Condición para saltar la línea si currency es USD y el título es "Impuesto PAIS"
                  if (props.currency === 'USD' && item.title === 'Impuesto PAIS') {
                      return null;
                  }
                  return (
                      <Fragment key={i}>
                          <Grid container className="item_resume">
                              <Grid item xs={ item.valor ? 7 : 12}>
                                  <Typography variant="body2">{item.title} </Typography>
                              </Grid>
                              {item.valor && 
                                <Grid item xs={5}>
                                  <Typography noWrap variant="body2" className="text-right">{props.currency} {item.valor} </Typography>
                              </Grid>}
                              
                          </Grid>
            </Fragment>
        );
    })}
</Fragment>

        </Popover>
    </Fragment>
  );
}
