import axios from "axios";
import config from "../config/config.json"

const endpoint_stg = 'https://1icm5bl099.execute-api.us-east-1.amazonaws.com/stg';
const endpoint_dev = 'https://y43e3jv1u1.execute-api.us-east-1.amazonaws.com/dev';
const endpoint_prod = 'https://qfxt620jf7.execute-api.us-east-1.amazonaws.com/prod';
const endpoint_dev_assists = 'https://ovhd75c32e.execute-api.us-east-1.amazonaws.com/dev-assists';
const endpoint_dev_alt = 'https://5tom8t3op1.execute-api.us-east-1.amazonaws.com/dev-alt';
const endpoint = endpoint_dev;


export const getPrebooking = async (request) => {
  const headers = {
    'Content-Type': "application/json",
    'token': config.client.token
    };


  const url = endpoint+'/initSteps'
   const response = await axios.post(url, JSON.stringify(request), {headers:headers})
    .then(res => {
      //console.log(res);
      return res.data;
    })
    .catch((err) => {
      //console.log(err);
      return false;
    }); 

  return response;
}

export const getPayment =  async  (request) => {

  const url = endpoint+'/initStepPayment';
  const headers ={
    'content-type': "application/json",
    'token': config.client.token
  };
  const response =  await axios.post(url,JSON.stringify(request),{headers:headers})
  .then(res => {
    //console.log(res);
    return res.data;
  })
  .catch((err) => {
    //console.log(err);
    return false;
  }); 

  return response;
  
}

export const getConfirmation = async (request) => {
  const headers ={
    'Content-Type': "application/json",
    "token": config.client.token

  };

  const url = endpoint+'/initStepConfirmation';
  const response = await axios.post(url, JSON.stringify(request),{headers:headers})
    .then(res => {
      //console.log(res);
      return res.data;
    })
    .catch((err) => {
      //console.log(err);
      return false;
    });

  return response;
}

export const getBooking = async (request) => {
  const headers ={
    'Content-Type': "application/json",
    "token": config.client.token

  };


   	//const url = 'https://1icm5bl099.execute-api.us-east-1.amazonaws.com/stg/lastStep';
    //const url = 'https://apigw-api-checkout.api-services-group.com/api-checkout/dev-assists/lastStep';
    //const url = 'https://apigw-api-checkout.api-services-group.com/api-checkout/prod/lastStep';
   	//const url = 'https://apigw-api-checkout.api-services-group.com/api-checkout/dev-alt/lastStep';
    //const url = 'https://apigw-api-checkout.api-services-group.com/api-checkout/stg/lastStep';
    const url = 'https://apigw-api-checkout.api-services-group.com/api-checkout/dev/lastStep';
    //const url = 'https://d2enb6p7uwvvfo.cloudfront.net/dev/lastStep';

    //const url = endpoint+'/lastStep';

   	const response = await axios.post(url, JSON.stringify(request),{headers:headers})
   	.then(res => {
     	//console.log(res);
     	return res.data;
   	})
   	.catch((err) => {
     	//console.log(err);
     	return false;
   	});

  	return response;
}
