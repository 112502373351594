import React, { Component, Fragment } from 'react'
import { Paper, Typography, Icon } from '@material-ui/core'
//import PopoverSimpleTitleContent from "./layout/PopoverSimpleTitleContent";
import DialogTimer from './layout/DialogTimer';

export default class Timer extends Component {
    state = {
        //totalMinutes: this.props.timer.value,
        minutes: this.props.timer.value,
        seconds: 0,
    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        const { /* totalMinutes,  */minutes, seconds } = this.state
        const {layoutTimer, timer, expired, module } = this.props;
        
        const GetMessages = (props) => {
            if (timer.messages) {
                const messages = timer.messages
                return (
                    <Fragment>{messages.map((message, id) => 
                        <div className="col_text" key={id}>
                            {message.items.map((item, id) => <Fragment key={id}>
                                    {item.title && <Typography className="title_timer" variant="body1">{item.title}</Typography>}
                                    {item.description && <Typography className="description_timer" variant="body2">{item.description}</Typography>}
                                </Fragment>
                            )}
                        </div>
                    )}</Fragment>
                )
            }
        }

        return (
            <Fragment>{layoutTimer[module] &&
                <Paper className={`paper timer timerv2 ${layoutTimer.template}`}>
                    {(minutes === 0 && seconds === 0) || expired
                        ? <DialogTimer redirect={timer.redirect} info={timer.infoModal} />
                        : <Fragment>
                            <div className="timer_icon">
                                <div className="icon_left"><Icon>access_time</Icon></div>
                            </div>
                            <div className="timer_content">
                            {/* {console.log(`Timer test: ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`)} */}
                                <GetMessages />
                                <div className="col_number">
                                    <div className="time">{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>
                                </div>
                            </div>
                        </Fragment>}                
                </Paper>
            }</Fragment>
        )
    }
}
